<template>
  <div>
    <Pane />

    <a-card class="container">
      <a-form
        :form="form"
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 12 }"
        :colon="false"
        @submit="handleSubmit"
      >
        <a-row>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="姓名">
              <a-input
                v-decorator="[
                  'name',
                  { rules: [{ required: true, message: '请输入！' }] },
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="联系方式">
              <a-input
                v-decorator="[
                  'mobile',
                  { rules: [{ required: true, message: '请输入！' }] },
                ]"
              />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="应聘部门">
              <a-select
                show-search
                :filter-option="$selectFilterOption"
                v-decorator="[
                  'deptId',
                  { rules: [{ required: true, message: '请选择！' }] },
                ]"
                style="width: 100%"
                placeholder=""
              >
                <a-select-option
                  v-for="item in filteredOrganizationList"
                  :key="item.id"
                  :value="item.id"
                  >{{ item.uniqueName }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="应聘岗位">
              <a-input
                v-decorator="[
                  'post',
                  { rules: [{ required: true, message: '请输入！' }] },
                ]"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <div class="center">
          <a-space>
            <a-button type="primary" html-type="submit" :loading="saveLoading"
              >保存</a-button
            >
            <a-button @click="$close($route.path)">关闭</a-button>
          </a-space>
        </div>
      </a-form>
    </a-card>
  </div>
</template>

<script>
import organization from "@/mixins/organization";
import { add } from "@/api/recruit";
import { mapGetters } from 'vuex';

export default {
  name: "addRecruit",
  mixins: [organization],

  data() {
    return {
      form: this.$form.createForm(this),
      saveLoading: false,
    };
  },

  computed: {
    ...mapGetters('auth', ['isRecruitmentManager', 'department']),

    filteredOrganizationList() {
      if (this.isRecruitmentManager) {
        return this.organizationList;
      } else {
        return this.organizationList.filter(item => item.id === this.department.deptId)
      }
    }
  },

  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.saveLoading = true;

          const department = this.organizationList.find(
            (item) => item.id === values.deptId
          );

          add({
            ...values,

            deptName: department ? department.uniqueName : "",
          })
            .then(() => {
              this.form.resetFields();
            })
            .finally(() => {
              this.saveLoading = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  padding: 24px 160px;
}

.center {
  margin-top: 80px;
}
</style>
